@use '~@amzn/awsui-design-tokens/polaris' as awsui;
@use '../../styles/base';

// 912px is the container breakpoint-s, the viewport breakpoint is 912(container width) + 40(closed side navigation width) + 4(negative margin from grid)
$viewport-breakpoint-s: 957px;
$open-navigation-width: 240px;

.custom-home__header {
    background-color: awsui.$color-background-home-header;
    display: flex;
    justify-content: center;
}

.custom-home__header-title {
    color: awsui.$color-text-home-header-default;
    text-align: center;
}

.custom-home__category,
.custom-home__header-sub-title {
    color: awsui.$color-text-home-header-secondary;
}

.custom-text__proper-case {
    text-transform: capitalize;
}

@media only screen and (min-width: $viewport-breakpoint-s) {
    .custom-home__sidebar {
        // Aligns baseline of text with dark header bottom border
        margin-top: -86px;
    }
    .custom-home__header-cta {
        // Keeps 32px distance between sidebar cards
        margin-top: 54px;
        margin-bottom: 38px;
    }
}

.custom-home__header-title > * {
    max-width: 700px;
}

.custom-home-image__placeholder:before {
    content: 'X';
    display: block;
    background-color: awsui.$color-background-input-disabled;
    color: awsui.$color-background-container-content;
    text-align: center;
    font-size: 400px;
    line-height: 400px;
}

.sourceInfo {
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 3px;
    font-family: monospace;
    font-size: 14px;
    padding: 10px;
    white-space: pre-wrap;
}
