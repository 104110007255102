// Note: These styles are not necessary for AWS Consoles

@use '~@amzn/awsui-design-tokens/polaris' as awsui;

body {
    background: awsui.$color-background-layout-main;
    position: relative;
    margin: 0;
}

.page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(97vh - 145px);
    /* margin: 2% 2% 2% 2%; */
    background-color: white;
    border: lightgrey solid 30px;
}

.alert-box {
    padding-left: 40px;
}

.custom-main-header {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    // corresponds to the z-index in Mezzanine
    z-index: 1000;
    margin: 0;
    background-color: #0f1b2a;
    font-family: awsui.$font-family-base;
}

ul.menu-list {
    height: 40px;
    font-size: 14px;
    padding: 0 20px;

    &,
    & ul {
        display: flex;
        align-items: center;
        margin: 0;
        list-style: none;
    }

    ul,
    li {
        padding: 0;
    }

    & li {
        margin: 0;
        padding: 0 8px;

        border-right: 2px solid #e9ebed;
        &:last-child {
            border-right: none;
        }

        li {
            border-right: 1px solid awsui.$color-border-control-default;
            &:first-child {
                padding-left: 0;
            }
            &:last-child {
                border-right: none;
                padding-right: 0;
            }
        }

        > a {
            padding: 0 6px;
        }

        &.title {
            margin-right: auto;
            border-right: none;
        }

        a,
        div,
        button,
        input,
        label {
            float: left;
            color: awsui.$color-text-interactive-default;
            line-height: 16px;
        }

        #visual-refresh-toggle {
            margin-right: 5px;
            margin-top: 1px;
        }

        a,
        a:hover {
            cursor: pointer;
            text-decoration: none;
        }

        &.title {
            font-weight: bold;
        }

        &.selected > a,
        &.selected > button {
            font-weight: bold;
            color: awsui.$color-text-accent;
        }
    }

    > li {
        padding: 0 20px;
    }

    button,
    button:focus {
        background: none;
        border: none;
        cursor: pointer;
        outline: none;
        padding: 0;
    }

    li.checkbox-item {
        margin-right: 0;
    }

    @media only screen and (max-width: 493px) {
        padding: 4px 20px;
        flex-wrap: wrap;
        height: fit-content;

        .title {
            flex: 1 1 100%;
            margin-bottom: 8px;
        }

        li {
            width: min-content;

            button,
            a {
                text-align: left;
            }

            a {
                padding: 0;
            }
        }
    }
}
