@use '~@amzn/awsui-design-tokens/polaris' as awsui;
@use './base';

.input-container {
    display: flex;
    flex-wrap: wrap;
    order: 0;
    flex-grow: 8;
    margin-right: 0;
    margin-bottom: -1rem;

    > * {
        margin-right: 1rem;
        margin-bottom: 1rem;
    }
}

.input-filter {
    order: 0;
    flex-grow: 6;
    width: 400px;
    max-width: 728px;
}

.select-filter {
    max-width: 130px;
    flex-grow: 3;
    width: auto;
}

.select-workflow-filter {
    max-width: 250px;
    flex-grow: 5;
    width: auto;
}

.select-workflow-expanded {
    max-width: 250px;
    flex-grow: 7;
    width: auto;
}

.count-filter {
    max-width: 130px;
    flex-grow: 3;
    width: auto;
}

.filtering-results {
    margin-left: 1rem;
    line-height: 3rem;
    color: awsui.$color-text-form-default;
}

@media (max-width: 1152px) {
    .input-container {
        margin-right: -1rem;
    }

    .select-filter {
        max-width: none;
    }

    .input-filter {
        width: 100%;
        max-width: none;
    }
}
